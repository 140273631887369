import service from '@/utils/request'; // @Tags CouponOrder
// @Summary 创建CouponOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponOrder true "创建CouponOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /couponOrder/createCouponOrder [post]

export var createCouponOrder = function createCouponOrder(data) {
  return service({
    url: "/couponOrder/createCouponOrder",
    method: 'post',
    data: data
  });
};
export var doRefund = function doRefund(data) {
  return service({
    url: "/couponOrder/doRefund",
    method: "post",
    data: data
  });
}; // @Tags CouponOrder
// @Summary 删除CouponOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponOrder true "删除CouponOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /couponOrder/deleteCouponOrder [delete]

export var deleteCouponOrder = function deleteCouponOrder(data) {
  return service({
    url: "/couponOrder/deleteCouponOrder",
    method: 'delete',
    data: data
  });
}; // @Tags CouponOrder
// @Summary 删除CouponOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除CouponOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /couponOrder/deleteCouponOrder [delete]

export var deleteCouponOrderByIds = function deleteCouponOrderByIds(data) {
  return service({
    url: "/couponOrder/deleteCouponOrderByIds",
    method: 'delete',
    data: data
  });
}; // @Tags CouponOrder
// @Summary 更新CouponOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponOrder true "更新CouponOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /couponOrder/updateCouponOrder [put]

export var updateCouponOrder = function updateCouponOrder(data) {
  return service({
    url: "/couponOrder/updateCouponOrder",
    method: 'put',
    data: data
  });
}; // @Tags CouponOrder
// @Summary 用id查询CouponOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponOrder true "用id查询CouponOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /couponOrder/findCouponOrder [get]

export var findCouponOrder = function findCouponOrder(params) {
  return service({
    url: "/couponOrder/findCouponOrder",
    method: 'get',
    params: params
  });
}; // @Tags CouponOrder
// @Summary 分页获取CouponOrder列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取CouponOrder列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /couponOrder/getCouponOrderList [get]

export var getCouponOrderList = function getCouponOrderList(params) {
  return service({
    url: "/couponOrder/getCouponOrderList",
    method: 'get',
    params: params
  });
};